import * as React from "react";
import { Helmet } from "react-helmet";

// markup
const IndexPage = () => {
  return (
    <div class="mx-auto p-5 sm:p-10">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alejo Arias</title>
        <link rel="canonical" href="https://www.alejoar.com/" />
      </Helmet>
      <div className="mx-auto bg-white font-extralight">
        <div>
          <h1 className="text-4xl text-black">Alejo Arias</h1>
          <h2 className="text-xl text-gray-700 mb-10">
            Software Engineer, Technical Lead, Founder and CTO based in Madrid, Spain
          </h2>
          <div className="flex flex-col space-y-2">
            <li className="">
              <a
                className="text-blue-900 hover:text-blue-400"
                target="_blank"
                href="https://github.com/alejoar"
              >
                github
              </a>
            </li>
            <li className="">
              <a
                className="text-blue-900 hover:text-blue-400"
                target="_blank"
                href="https://stackoverflow.com/users/3632543/aarias"
              >
                stackoverflow
              </a>
            </li>
            <li className="">
              <a
                className="text-blue-900 hover:text-blue-400"
                target="_blank"
                href="https://www.linkedin.com/in/alejoar/"
              >
                linkedin
              </a>
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
